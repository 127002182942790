/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF, useAnimations, Bounds,PerspectiveCamera } from '@react-three/drei';


export function Model(props) {
  const group = useRef(); 
  const { nodes, materials, animations } = useGLTF('/TonyShirt.glb');
  const { actions } = useAnimations(animations, group);


  
  return (
    <group ref={group} {...props} dispose={null}>
    <group name="Scene">
      <group
        name="Empty"
        position={[0, -0.535, 1.047]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.246}>
        <mesh
          name="Animated_Shirt"
          castShadow
          receiveShadow
          geometry={nodes.Animated_Shirt.geometry}
          material={materials['Shirt Texture']}
          position={[0, 0.038, -1.757]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={4.338}
        />
      </group>
    </group>
  </group>
  )
}

useGLTF.preload('/TonyShirt.glb')