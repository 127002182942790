import React, { Suspense, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stage, RandomizedLight, AccumulativeShadows } from '@react-three/drei';
import './App.css'; 
import { Model } from './Model';
import { EffectComposer, Bloom, ToneMapping } from '@react-three/postprocessing'


export default function App() {
  const ref = useRef()
  return (
    <div style={{ height: '100vh', 
      width: '100vw', 
      //backgroundImage: 'url("/background.png")', 
      //backgroundSize:'cover', 
      //background: "transparent",
      backgroundColor:"rgba(55,55,55,0.8)",
      overflow: 'hidden' }}>
<Canvas shadows={false} dpr={[1, 2]} camera={{ fov: 50 }}>
<ambientLight intensity={1} color="#ffffff"  />

      <Suspense fallback={null}>
        <Stage controls={ref} shadows={false}>
        <directionalLight position={[1, 5, 20]} intensity={0.5} color="#AB47BC" />
        false
          <Model />
        false
        </Stage>
      </Suspense>
 
      <EffectComposer disableNormalPass multisampling={4}>
        {/** The bloom pass is what will create glow, always set the threshold to 1, nothing will glow
         /*  except materials without tonemapping whose colors leave RGB 0-1 */}
        <Bloom mipmapBlur luminanceThreshold={1} intensity={0.1} />
        <ToneMapping adaptive resolution={256} middleGrey={0.5} maxLuminance={15.0} averageLuminance={1} adaptationRate={1} />
      </EffectComposer>
      <OrbitControls ref={ref} autoRotate={true} />
    </Canvas>
    </div>
  );
}

